<template>
  <div class="partner">
    <div class="inner">
      <div class="header">
        <div class="logo" @click="this.goto_main"></div>
        <h1>{{ this.$t("apply.title") }}</h1>
      </div>
      <div class="body">
        <div class="intro">{{ this.$t("apply.intro") }}</div>
        <div class="procedure">
          <h3>{{ this.$t("apply.procedure") }}</h3>
          <ul>
            <li>{{ this.$t("apply.procedure_step1") }}</li>
            <li>{{ this.$t("apply.procedure_step2") }}</li>
            <li>{{ this.$t("apply.procedure_step3") }}</li>
            <li>{{ this.$t("apply.procedure_step4") }}</li>
          </ul>
        </div>
        <div class="form">
          <ul>
            <li>
              <div class="label">{{ this.$t("apply.company_type") }}</div>

              <input
                type="radio"
                id="radio_company"
                name="company_type"
                value="COMPANY"
                v-model="this.company_type"
              />
              <label for="radio_company">{{ this.$t("apply.company") }}</label>

              <input
                type="radio"
                id="radio_person"
                name="company_type"
                value="PERSON"
                v-model="this.company_type"
              />
              <label for="radio_person">{{ this.$t("apply.person") }}</label>

              <input
                type="radio"
                id="radio_org"
                name="company_type"
                value="ORGANIZATION"
                v-model="this.company_type"
              />
              <label for="radio_org">{{ this.$t("apply.organization") }}</label>

              <input
                type="radio"
                id="radio_etc"
                name="company_type"
                value="ETC"
                v-model="this.company_type"
              />
              <label for="radio_etc">{{ this.$t("apply.etc") }}</label>
            </li>
            <li>
              <div class="label">{{ this.$t("apply.company_name") }}</div>
              <input type="text" v-model="this.company" />
            </li>
            <li>
              <div class="label">{{ this.$t("apply.person_name") }}</div>
              <input type="text" v-model="this.name" />
            </li>
            <li>
              <div class="label">{{ this.$t("apply.person_tel") }}</div>
              <input type="text" v-model="this.tel" />
            </li>
            <li>
              <div class="label">{{ this.$t("apply.person_email") }}</div>
              <input type="text" v-model="this.email" />
            </li>
            <li>
              <div class="label">{{ this.$t("apply.brand") }}</div>
              <input type="text" v-model="this.brand" />
            </li>
            <li>
              <div class="label">{{ this.$t("apply.inquiry_content") }}</div>
              <textarea v-model="this.content"></textarea>
            </li>
            <li>
              <vue-recaptcha
                sitekey="6LcK5uAjAAAAAEnepq8IK3gWYqESRo8B0nk3pUCp"
                @verify="this.verify_recaptcha"
                @expired="this.reset_recaptcha"
                @error="this.reset_recaptcha"
              ></vue-recaptcha>
            </li>
            <li>
              <input type="checkbox" id="check_agree" v-model="this.agree" />
              <label for="check_agree">{{ this.$t("apply.agree_info") }}</label>
            </li>
          </ul>
          <div class="button_wrap">
            <button class="button outline" @click="this.goto_main">
              {{ this.$t("common.cancel") }}
            </button>
            <button class="button red" @click="this.try_apply">
              {{ this.$t("apply.apply") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Spinner v-if="processing" />
</template>

<script>
import { VueRecaptcha } from "vue-recaptcha";
export default {
  name: "PartnerApply",
  components: { VueRecaptcha },
  data: function () {
    return {
      process_queue: [],
      company: "",
      company_type: "",
      name: "",
      email: "",
      tel: "",
      brand: "",
      content: "",
      agree: false,

      recaptcha_token: "",
    };
  },
  computed: {
    processing() {
      if (this.process_queue.length <= 0) return false;
      else return true;
    },
  },
  mounted() {
    // Nothing to do...
  },
  methods: {
    verify_recaptcha: function (response) {
      this.recaptcha_token = response;
    },
    reset_recaptcha: function () {
      this.recaptcha_token = "";
    },

    goto_main: function () {
      this.$router.push("/");
    },

    try_apply: function () {
      let self = this;
      if (this.company_type.trim() == "") {
        this.$swal.fire({
          text: self.$t("apply.error_company_type"),
          icon: "error",
        });
        return;
      }

      if (this.company.trim() == "") {
        this.$swal.fire({
          text: self.$t("apply.error_company_name"),
          icon: "error",
        });
        return;
      }

      if (this.name.trim() == "") {
        this.$swal.fire({
          text: self.$t("apply.error_person_name"),
          icon: "error",
        });
        return;
      }

      if (this.tel.trim() == "") {
        this.$swal.fire({
          text: self.$t("apply.error_person_tel"),
          icon: "error",
        });
        return;
      }

      if (this.email.trim() == "") {
        this.$swal.fire({
          text: self.$t("apply.error_person_email"),
          icon: "error",
        });
        return;
      }

      if (!this.common.validate_email(this.email.trim())) {
        this.$swal.fire({
          text: self.$t("apply.error_invalid_email"),
          icon: "error",
        });
        return;
      }

      if (this.brand.trim() == "") {
        this.$swal.fire({
          text: self.$t("apply.error_brand"),
          icon: "error",
        });
        return;
      }

      if (this.content.trim() == "") {
        this.$swal.fire({
          text: self.$t("apply.error_content"),
          icon: "error",
        });
        return;
      }

      if (this.recaptcha_token == "") {
        this.$swal.fire({
          text: self.$t("apply.error_recaptcha"),
          icon: "error",
        });
        return;
      }

      if (!this.agree) {
        this.$swal.fire({
          text: self.$t("apply.error_agree"),
          icon: "error",
        });
        return;
      }

      this.$swal
        .fire({
          text: this.$t("apply.confirm_apply"),
          icon: "question",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            self.save_apply();
          }
        }); //-- swal
    },

    save_apply: function () {
      if (this.processing) return;

      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/partner/apply",
          {
            company_type: this.company_type,
            company_name: this.company,
            person_name: this.name,
            person_tel: this.tel,
            person_email: this.email,
            brand: this.brand,
            inquiry_content: this.content,
            recaptcha_token: this.recaptcha_token,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          if (response.data.result != "OK") {
            self.$swal.fire({
              text: self.$t("common.error_occur"),
              icon: "error",
            });
            return;
          }

          self.$swal
            .fire({
              text: self.$t("apply.apply_ok"),
              icon: "success",
            })
            .then(() => {
              this.$router.push("/");
            });
        })
        .finally(function () {
          self.process_queue.pop();
        }); //-- axios
    },
  },
};
</script>

<style scoped>
.partner {
  position: relative;

  width: 100%;
  min-height: 100vh;
  z-index: 0;

  padding: 2rem 0;

  overflow-y: auto;

  background-color: #000;
  background-size: auto 100%;
  background-image: url("../../assets/img/img_login_background.jpg");
  background-repeat: repeat;
  background-attachment: fixed;
  animation: 120s linear 0s infinite normal none running avatar_animation;
}
@keyframes avatar_animation {
  0% {
    background-position: 0px 0px;
  }
  100% {
    background-position: calc(-100vw + 100%) 0px;
  }
}
.partner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  z-index: 0;
}

.partner > .inner {
  position: relative;
  display: block;
  width: 1000px;
  max-width: 100%;
  margin: 2rem auto;
  background: #fff;
  z-index: 1;
}
.partner > .inner > .header {
  padding: 0.4rem 2rem;
  border-bottom: 1px solid #ccc;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.partner > .inner > .header > .logo {
  width: 4rem;
  height: 2rem;
  margin: 0 1rem 0 0;
  background: url("../../assets/img/logo_600.png") no-repeat;
  background-position: center center;
  background-size: contain;
  cursor: pointer;
}
.partner > .inner > .header > h1 {
  font-size: 1.6rem;
  font-weight: 700;
}
.partner > .inner > .body {
  padding: 1.4rem 2rem;
}
.partner > .inner > .body > .intro {
  background: #efefef;
  border-radius: 0.4rem;
  padding: 1rem 1.4rem;
  margin: 0 0 1rem 0;
}
.partner > .inner > .body > .procedure {
  border: 1px solid #ccc;
  border-radius: 0.4rem;
  padding: 1rem 1.4rem;
  margin: 0 0 1rem 0;
}
.partner > .inner > .body > .procedure > h3 {
  font-size: 1rem;
  font-weight: 700;
  padding: 0;
  margin: 0 0 0.4rem 0;
}
.partner > .inner > .body > .procedure > ul {
  list-style-type: square;
  padding-inline-start: 2rem;
  margin: 0;
}
.partner > .inner > .body > .procedure > ul > li {
  padding: 0.1rem 0;
  margin: 0;
}
.partner > .inner > .body > .procedure > ul > li::marker {
  color: #e74356;
}

.partner > .inner > .body > .form {
  margin: 0;
}
.partner > .inner > .body > .form > ul {
  list-style-type: none;
  padding: 0;
  margin: 0 0 2rem 0;
}
.partner > .inner > .body > .form > ul > li {
  padding: 0.8rem 0;
  margin: 0;
}
.partner > .inner > .body > .form > ul > li > .label {
  font-size: 1.15rem;
  font-weight: 500;
  padding: 0;
  margin: 0 0 0.6rem 0;
}
.partner > .inner > .body > .form > ul > li > input[type="text"] {
  width: 100%;
}
.partner > .inner > .body > .form > ul > li > textarea {
  width: 100%;
  height: 8rem;
  resize: none;
}
.partner > .inner > .body > .form > ul > li > input[type="radio"] + label {
  display: inline-block;
  vertical-align: middle;
  font-size: 1rem;
  font-weight: 400;
  margin: 0 2rem 0 0.4rem;
}
.partner > .inner > .body > .form > ul > li > input[type="checkbox"] {
  display: inline-block;
  vertical-align: middle;
}
.partner > .inner > .body > .form > ul > li > input[type="checkbox"] + label {
  display: inline-block;
  vertical-align: middle;
  font-size: 1rem;
  font-weight: 700;
  margin: 0 2rem 0 0.4rem;
}
.partner > .inner > .body > .form > .button_wrap {
  text-align: center;
}
.partner > .inner > .body > .form > .button_wrap > button.button {
  margin: 0 0.4rem;
  text-align: center;
}
</style>
